<template>
  <Toast />
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="mr-auto text-lg font-bold">Listado de Compras</h2>
          <Button icon="pi pi-plus" label="Expandir" @click="expandAll" class="p-mr-2 p-button-xs" />
          <Button icon="pi pi-minus" label="Colapsar" @click="collapseAll" class="p-button-xs" />
        </div>

        <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-12">
            <DataTable
              showGridlines
              ref="dt"
              :lazy="true"
              :auto-layout="true"
              :value="purchasesList"
              v-model:expandedRows="expandedRows"
              class="p-datatable-sm p-2 text-sm"
              dataKey="id"
              :paginator="true"
              :rows="10"
              :totalRecords="totalRecords"
              :loading="loading"
              @page="onPage($event)"
              @sort="onSort($event)"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              :rowsPerPageOptions="[5,10,25]"
              currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
              responsiveLayout="stack"
              breakpoint="960px"
              @rowExpand="onRowExpand"
              @rowCollapse="onRowCollapse"
            >
              <template #header>
                <filters-shopping v-model="filters" @search="onFilter" />
              </template>

              <!-- Columnas -->
              <Column :expander="true" headerStyle="width: 3rem"/>
              <Column field="name" header="Banco" :sortable="true" headerStyle="width: 15rem;">
                <template #body="{data}">
                  {{ data.AmortizationsProduct.AmortizationsBank.nameBank }}
                </template>
              </Column>
              <Column field="obligationNumber" header="# Obligación" headerStyle="width: 12rem;">
                <template #body="{data}">
                  {{ data.AmortizationsProduct.obligationNumber }}
                </template>
              </Column>
              <Column field="diferido" header="# Cuotas Diferido" headerStyle="width: 12rem;" style="text-align:center;"></Column>
              <Column field="value" header="Valor" headerStyle="width: 12rem;" style="text-align: right">
                <template #body="{data}">
                  {{ $h.formatCurrency(parseInt(data.value)) }}
                </template>
              </Column>
              <Column field="interest" header="Interés" headerStyle="width: 12rem;" style="text-align: right">
                <template #body="{data}">
                  {{ $h.formatCurrency(parseInt(data.interest)) }}
                </template>
              </Column>
              <Column field="purchaseDate" header="Fecha Compra" headerStyle="width: 10rem;" style="text-align:center;"></Column>
              <Column field="description" header="Descripción" headerStyle="width: 20rem;"></Column>
              <Column :exportable="false"  v-if="$can('pharmasan.contabilidad.amortizaciones.compras.access')" header="Acciones" style="text-align: center; min-width:10rem">
                <template #body="{data}">
                  <router-link
                    :to="{name: 'pharmasan.contabilidad.amortizaciones.productos-financieros.compras.formulario', params: {id: data.id}}"
                  >
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" v-tooltip.top="'Editar'"/>
                  </router-link>
                  <Button
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger"
                    v-tooltip.top="'Eliminar'"
                    @click="removeShopping(data)"
                  />
                </template>
              </Column>

              <template #expansion="slotProps">
                <div class="orders-subtable">
                  <h5>Detalle de los valores de la compra</h5>
                  <DataTable
                    :value="slotProps.data.AmortizationsShoppingDetail"
                    showGridlines
                    responsiveLayout="scroll"
                    class="p-datatable-sm p-2 text-sm"
                  >
                    <Column header="Cuota">
                      <template #body="{data}">
                        {{ data.quota }}
                      </template>
                    </Column>
                    <Column header="Valor">
                      <template #body="{data}">
                        {{ $h.formatCurrency(parseInt(data.value)) }}
                      </template>
                    </Column>
                    <Column header="Interés" >
                      <template #body="{data}">
                        {{ $h.formatCurrency(parseInt(data.interest)) }}
                      </template>
                    </Column>
                    <Column :exportable="false" header="Acciones" style="text-align: center" headerStyle="width: 15rem;">
                      <template #body="{ data }">
                        <Button
                          icon="pi pi-pencil"
                          class="p-button-rounded p-button-warning p-mr-2"
                          @click="openDialogEdit(data, slotProps.data.id)"
                          v-tooltip.top="'Editar Detalle'"
                        />
                        <Button
                          icon="pi pi-trash"
                          class="p-button-rounded p-button-danger"
                          @click="removeShoppingDetails(data, slotProps.data.id)"
                          v-tooltip.top="'Eliminar Detalle'"/>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Modal para editar el detalle de la compra -->
  <Dialog
    v-model:visible="displayDialogEditDetailShopping"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    :modal="true"
    class="p-fluid custom-style"
  >
    <template #header>
      <span class="text-lg p-text-bold text-yellow-600">Editar Detalle Compra</span>
    </template>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="installmentNumber">Cuota:</label>
        <InputText
          id="installmentNumber"
          type="number"
          v-model="detailShopping.quota"
          :class="{ 'p-invalid': errors.quota }"
          autofocus
        />
        <MessageError :text="errors.quota" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="value">Valor ($):</label>
        <InputNumber
          id="value"
          v-model="detailShopping.value"
          mode="currency"
          currency="COP"
          locale="es-CO"
          minFractionDigits="0"
          maxFractionDigits="0"
          :class="{ 'p-invalid': errors.value }"
        />
        <MessageError :text="errors.value" />
      </div>
      <div class="p-field p-col-12 p-md-12">
        <label for="fee">Interés ($):</label>
        <InputNumber
          id="interest"
          v-model="detailShopping.interest"
          mode="currency"
          currency="COP"
          locale="es-CO"
          minFractionDigits="0"
          maxFractionDigits="0"
          :class="{ 'p-invalid': errors.interest }"
        />
        <MessageError :text="errors.interest" />
      </div>
    </div>
    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" @click="closeResponsive" class="p-button-text p-button-danger"/>
      <Button label="Editar" icon="pi pi-pencil" @click="onSubmitEditDetails" class="p-button-sm p-button-success"/>
    </template>
  </Dialog>

</template>

<script>
import { defineAsyncComponent, onMounted, ref } from 'vue'
import listShopping from '../../../../services/listShopping'
import listShoppingDetails from '../../../../services/listShoppingDetails'
import { error, messageWarningDelete } from '../../../../../../../../libs/mensajes'
import { useToast } from 'primevue/usetoast'
import deleteShopping from '../../../../services/deleteShopping'
import deleteShoppingDetail from '../../../../services/deleteShoppingDetail'
import { number, object } from 'yup'
import { useField, useForm } from 'vee-validate'
import updateShoppingDetails from '../../../../services/updateShoppingDetails'
import updateShopping from '../../../../services/updateShopping'

export default {
  name: 'listShoppings',
  components: {
    filtersShopping: defineAsyncComponent(() => import('../filters'))
  },
  setup () {
    const toast = useToast()
    const totalRecords = ref(0)
    const loading = ref(true)
    const lazyParams = ref({})
    const currentPage = ref(1)
    const sortBy = ref('id')
    const sortType = ref('desc')
    const dt = ref()
    const displayDialogEditDetailShopping = ref(false)
    const purchasesList = ref([])
    const purchasesDetails = ref([])
    const purchaseId = ref()
    const expandedRows = ref([])
    const filters = ref({
      bankId: { value: null, matchMode: 'contains' },
      obligationNumber: { value: '', matchMode: 'contains' },
      dateStart: { value: null, matchMode: 'contains' },
      dateEnd: { value: null, matchMode: 'contains' }
    })

    /* Obtener el listado de compras  */
    const fetchShoppingList = (event = false, typeEvent, filters) => {
      let queryParams = {}
      if (!event) {
        queryParams = {
          current_page: 1,
          per_page: 10,
          sort_by: sortBy.value,
          sort_type: sortType.value
        }
      } else {
        if (typeEvent === 'sort') {
          sortBy.value = event.sortField
          sortType.value = `${event.sortOrder === 1 ? 'ASC' : 'DESC'}`
        }

        if (!['', null, undefined].includes(filters.dateStart.value) && !['', null, undefined].includes(filters.dateEnd.value)) {
          queryParams.purchaseDate = `${filters.dateStart.value},${filters.dateEnd.value}`
        }

        queryParams = {
          ...queryParams,
          current_page: `${typeEvent === 'sort' || typeEvent === 'filter' ? currentPage.value : event.page + 1}`,
          per_page: event.rows,
          sort_by: sortBy.value,
          sort_type: sortType.value,
          bankId: filters.bankId.value ? filters.bankId.value : null,
          obligationNumber: filters.obligationNumber.value ? filters.obligationNumber.value : null
        }
      }

      loading.value = true
      return listShopping(queryParams).then(({ status, data: response }) => {
        console.log(response.result.data)
        loading.value = false
        // purchasesList.value = response.result.data
        totalRecords.value = response.result.total

        purchasesList.value = response.result.data.map((tblPurchaseList) => {
          const value = tblPurchaseList.AmortizationsShoppingDetail.reduce((tot, arr) => {
            return tot + arr.value
          }, 0)

          const interest = tblPurchaseList.AmortizationsShoppingDetail.reduce((tot, arr) => {
            return tot + arr.interest
          }, 0)

          return {
            ...tblPurchaseList,
            diferido: tblPurchaseList.AmortizationsShoppingDetail.length,
            value,
            interest
          }
        })
      }).catch(err => {
        loading.value = false
        console.error(err.message)
      })
    }

    const onPage = (event) => {
      lazyParams.value = event
      fetchShoppingList(event, 'page', filters.value)
    }

    const onSort = (event) => {
      lazyParams.value = event
      fetchShoppingList(event, 'sort', filters.value)
    }

    const onFilter = (event) => {
      fetchShoppingList(event, 'filter', filters.value)
    }

    const onRowCollapse = () => {
      purchasesDetails.value = []
    }
    const onRowExpand = (event) => {
      console.log('Event expand: ', event)
    }

    /* Funcion para expandir todos los detalles de la tabla */
    const expandAll = async () => {
      expandedRows.value = purchasesList.value.filter(async (tableReg) => {
        const response = await listShoppingDetails({ id: tableReg.id })
        purchasesDetails.value = response.data.result ?? []
      })
    }
    /* Funcion para colapsar todos los detalles de la tabla */
    const collapseAll = () => {
      expandedRows.value = null
    }

    /* Abrir el modal para editar */
    const openDialogEdit = (editData, idMain) => {
      purchaseId.value = idMain

      for (const [key, value] of Object.entries(editData)) {
        detailShopping[key] = value
      }
      displayDialogEditDetailShopping.value = true
    }

    /* Evento de funcion cuando se cierra el modal */
    const closeResponsive = () => {
      displayDialogEditDetailShopping.value = false
    }

    /* Esquema de validacion con sus reglas */
    const validationSchema = object({
      quota: number().typeError('El campo es requerido').positive().label('Cuota'),
      value: number().typeError('El campo es requerido').label('Valor'),
      interest: number().typeError('El campo es requerido').label('Interes')
    })

    /* Variables y propiedades del formulario para validar */
    const { errors, handleSubmit, values: detailShopping } = useForm({
      validationSchema
    })

    /* Inicializando campo en el formulario del detalle de la compra */
    useField('id', null, { initialValue: 0 })
    useField('quota', null, { initialValue: 0 })
    useField('value', null, { initialValue: 0 })
    useField('interest', null, { initialValue: 0 })

    // Submit del envio de los datos
    const onSubmitEditDetails = handleSubmit((values) => {
      delete values.id

      updateShoppingDetails(detailShopping.id, [values]).then(async ({ data, status }) => {
        if (data.message === 'OK' && status === 200) {
          // loader.hide()
          toast.add({
            severity: 'success',
            summary: 'Exito',
            detail: 'Registro del detalle de la compra actualizado exitosamente',
            life: 3000
          })

          await fetchShoppingList()

          const infoPurchaseEdit = purchasesList.value.find((item, i) => {
            return item.id === purchaseId.value
          })

          updateShopping(purchaseId.value, {
            value: infoPurchaseEdit.value,
            interest: infoPurchaseEdit.interest
          }).then(async ({ data, status }) => {
            displayDialogEditDetailShopping.value = false
          }).catch((err) => {
            // loader.hide()
            console.error(err.message)
            if (err.response.status) {
              error(err.response.data.message)
            }
          })
        }
      }).catch((err) => {
        // loader.hide()
        console.error(err.message)
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    })

    /* Eliminar encabezado de compras */
    const removeShopping = (data) => {
      const { id } = data

      messageWarningDelete(
        '',
        'Eliminar Registro Compra',
        'warning',
        '¿Esta seguro de eliminar la compra, esta acción es irreversible?'
      ).then(({ isConfirmed }) => {
        if (isConfirmed) {
          deleteShopping(id).then(() => {
            toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro de compra eliminado correctamente', life: 3000 })
            fetchShoppingList()
          }).catch((err) => {
            console.error(err.message)
            error(err.response.data.message)
          })
        }
      })
    }

    /* Eliminar registro del detalle de compras */
    const removeShoppingDetails = (data, idMain) => {
      const { id } = data

      messageWarningDelete(
        '',
        'Eliminar Detalle Compra',
        'warning',
        '¿Esta seguro de eliminar el detalle de la compra, esta acción es irreversible?'
      ).then(({ isConfirmed }) => {
        if (isConfirmed) {
          deleteShoppingDetail(id).then(async () => {
            await fetchShoppingList()

            const infoPurchaseEdit = purchasesList.value.find((item, i) => {
              return item.id === idMain
            })

            updateShopping(idMain, {
              value: infoPurchaseEdit.value,
              interest: infoPurchaseEdit.interest,
              diferido: infoPurchaseEdit.AmortizationsShoppingDetail.length
            }).then(async ({ data, status }) => {
              toast.add({ severity: 'success', summary: 'Exito', detail: 'Registro del detalle de compra eliminado correctamente', life: 3000 })
            }).catch((err) => {
              // loader.hide()
              console.error(err.message)
              if (err.response.status) {
                error(err.response.data.message)
              }
            })
          }).catch((err) => {
            console.error(err.message)
            error(err.response.data.message)
          })
        }
      })
    }

    onMounted(() => {
      loading.value = true

      lazyParams.value = {
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
        filters: filters.value
      }

      fetchShoppingList()
    })

    return {
      dt,
      onPage,
      onSort,
      onFilter,
      totalRecords,
      purchasesList,
      filters,
      purchaseId,
      removeShopping,
      removeShoppingDetails,
      expandedRows,
      loading,
      expandAll,
      collapseAll,
      onRowCollapse,
      onRowExpand,
      onSubmitEditDetails,
      closeResponsive,
      displayDialogEditDetailShopping,
      openDialogEdit,
      errors,
      detailShopping
    }
  }
}
</script>

<style scoped>

</style>
